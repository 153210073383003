<!--
 * @Description: 采购商订单详情
 * @Author: zhang zhen
 * @Date: 2023-05-16 17:14:00
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-16 10:17:51
 * @FilePath: /page-sass/src/views/requirement/orderManagement/modules/orderDetailsForUser.vue
-->
<template>
  <div>
    <div class="ForUserAndPurchaseDetails info-container-box">
      <div class="info-area-top">
        <div class="left-side">
          <div class="ForUserAndPurchaseDetails-title">{{ orderTitle }}</div>
          <div class="ForUserAndPurchaseDetails-subtitle">
            <div class="header-options">
              <span class="label">订单编号：{{ orderNo }}</span>
              <a class="copy" @click="handleCopy(orderNo)">复制</a>
            </div>
          </div>
        </div>
        <a-space class="action-part">
          <!--          <a-button ghost type="primary" @click="handleChat">-->
          <!--            <div class="btn-inner">-->
          <!--              <img src="~@/assets/message-3-line.png" alt="" class="icon" />-->
          <!--              <span class="text">咨询TA</span>-->
          <!--            </div>-->
          <!--          </a-button>-->
          <a-button ghost type="primary" @click="handleOrderDownload">
            <div class="btn-inner">
              <span class="text">下载订单协议</span>
            </div>
          </a-button>
          <a-button ghost type="primary" @click="handleChat">
            <div class="btn-inner">
              <span class="text">下载订单信息</span>
            </div>
          </a-button>
          <!-- 当订单的paymentMethod=1044001或者1044002时，并且订单状态在未发货之前 -->
          <a-button
            type="primary"
            v-if="(paymentMethod == '1044002' || paymentMethod == '1044001') && orderStatus == 4"
            @click="showApplyReturnInfo = true"
          >申请退定金
          </a-button
          >
          <!-- 订单的afterSaleStatus==0并且用户是采购商 -->
          <a-button
            type="primary"
            v-if="afterSaleStatus == 0 && orderStatus > 8 && orderStatus < 9 && payAmount > 0"
            @click="showApplyReturnMoney = true"
          >退款
          </a-button
          >
          <template v-if="afterSaleStatus == 4">
            <!-- exchangeStatus==1 展示按钮给采购商提供寄出旧商品的页面 调用换货寄出旧商品接口：/afterSale/exchange/sendOldGoods-->
            <a-button type="primary" v-if="exchangeStatus == 1" @click="handleSendOldGoods">寄出旧商品</a-button>
            <!-- 如果==2 按钮置灰展示文本【寄出旧商品，等待换货中】 -->
            <a-button type="primary" v-if="exchangeStatus == 2" :disabled="true">旧商品已寄出，等待换货中</a-button>
            <!-- 如果==3 按钮显示文本【新商品已发货，等待确认】，点击后调用/afterSale/exchange/receiveGoods 确认收货 -->
            <a-button type="primary" v-if="exchangeStatus == 3" @click="handleConfirmShip">新商品已发货，等待确认
            </a-button>
          </template>
          <!-- 订单的afterSaleStatus==0并且用户是采购商 -->
          <a-button type="primary" @click="showApplyExchange = true"
                    v-if="afterSaleStatus == 0 && orderStatus > 6 && orderStatus < 9"
          >换货
          </a-button
          >
          <a-button type="primary" icon="close-circle" v-if="cancelOrder" @click="showRemoveOrder = true"
          >取消订单
          </a-button
          >
        </a-space>
      </div>
      <a-tabs v-model="activeTab" @change="handleChangeTabs">
        <!--        <a-tab-pane key="4" tab="详情">-->
        <!--          <RequirementInfo-->
        <!--            ref="RequirementInfoRef"-->
        <!--            :needSteps="true"-->
        <!--            :showOrderInfo="false"-->
        <!--            :purchaseId="purchaseId"-->
        <!--            :needLoadNow="true"-->
        <!--            :needMoreInfo="false"-->
        <!--            @updateOrderInfo="handleEmitUpdate"-->
        <!--          />-->
        <!--        </a-tab-pane>-->
        <a-tab-pane key="1" tab="概览">
          <SupplierInfo ref="SupplierInfo" :orderNo="orderNo" :needEdit="false" />
        </a-tab-pane>
<!--                <a-tab-pane key="2" tab="单据">-->
<!--                  <BillUploader ref="BillUploader" :orderNo="orderNo" :orderStatus="orderStatus" @ok="handleLoadInfo" />-->
<!--                </a-tab-pane>-->
<!--        <a-tab-pane key="3" tab="协议">-->
<!--          <ContractPart-->
<!--            ref="ContractPart"-->
<!--            :orderNo="orderNo"-->
<!--            :orderStatus="orderStatus"-->
<!--            fileKey="contractFileCustomer"-->
<!--          />-->
<!--        </a-tab-pane>-->
        <!--        <a-tab-pane key="7" tab="评价">-->
        <!--          <AppraisalInfo :orderNo="orderNo" />-->
        <!--        </a-tab-pane>-->
      </a-tabs>
    </div>
    <!-- 取消订单提示 -->
    <a-modal
      title="温馨提示"
      :visible="showRemoveOrder"
      centered
      @ok="handleRemoveOrder"
      @cancel="showRemoveOrder = false"
    >
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您确定取消订单吗?</span>
      </div>
    </a-modal>
    <!-- 退款定金 -->
    <a-modal
      title="温馨提示"
      :visible="showApplyReturnInfo"
      :width="600"
      centered
      @ok="handleNextStep"
      @cancel="showApplyReturnInfo = false"
      okText="确认"
    >
      <div class="info-card">
        <div class="info">
          <img src="/warning.png" alt="" />
          <span>您是否确认进行定金退款?</span>
        </div>
        <div class="memo">( * 退款时间以及退款规则参考<span class="link">《退款规则》</span> )</div>
      </div>
    </a-modal>
    <!-- 退定金弹窗 -->
    <ApplyReturnDeposit ref="ApplyReturnDepositRef" @ok="handleLoadInfo" />
    <!-- 退款提示框子 -->
    <a-modal
      title="请选择退款方式"
      :visible="showApplyReturnMoney"
      :width="600"
      centered
      @ok="handleShowReturnMoney"
      @cancel="showApplyReturnMoney = false"
      okText="确认"
    >
      <div class="info-card">
        <a-radio-group v-model="returnType">
          <a-radio :style="radioStyle" value="2"> 部分</a-radio>
          <a-radio :style="radioStyle" value="3"> 全部</a-radio>
        </a-radio-group>
        <a-divider style="margin-top: 15px; margin-bottom: 15px" />
        <div class="memo" style="padding-left: 0">
          ( 注意:退款时间以及退款规则参考<span class="link">《退款规则》</span> )
        </div>
      </div>
    </a-modal>
    <!-- 退款表单 -->
    <ApplyReturnMoney ref="ApplyReturnMoneyRef" @ok="handleLoadInfo" />
    <!-- 换货 -->
    <a-modal
      title="请选择换货方式"
      :visible="showApplyExchange"
      :width="600"
      centered
      @ok="handleShowExchange"
      @cancel="showApplyExchange = false"
      okText="确认"
    >
      <div class="info-card">
        <a-radio-group v-model="exchangeType">
          <a-radio :style="radioStyle" value="1"> 部分</a-radio>
          <a-radio :style="radioStyle" value="2"> 全部</a-radio>
          <!-- <a-radio :style="radioStyle" value="3">
            换货
          </a-radio> -->
        </a-radio-group>
        <a-divider style="margin-top: 15px; margin-bottom: 15px" />
        <div class="memo" style="padding-left: 0">
          ( 注意:换货时间以及换货规则参考<span class="link">《换货规则》</span> )
        </div>
      </div>
    </a-modal>
    <ApplyExchange ref="ApplyExchangeRef" @ok="handleLoadInfo" />
    <!-- 换货寄商品 -->
    <EnterReturnInfo ref="EnterReturnInfoRef" @ok="handleLoadInfo" />
    <!--订单协议-->
<!--    <FileListView ref="FileListViewRef" />-->
  </div>
</template>

<script>
import InterestedCard from '@/components/tools/interestedCard.vue'
import BasicInfo from './children/basicInfo.vue'
import UploadBills from './children/uploadBills.vue'
import ContractPart from './children/contractPart.vue'
import FacilitatorBills from './children/facilitatorBills.vue'
import UploadProgress from './children/UploadProgress.vue'
import SupplierInfo from './children/supplierInfoForUser.vue'
import SupplierUploadProgress from './children/supplierUploadProgress.vue'
import BillUploader from './children/billUploader.vue'
import { getAction, postAction, postQueryAction, putAction } from '@/api/manage'
import InfoDetails from './children/infoDetails.vue'
import AppraisalInfo from './children/appraisalInfo.vue'
import RequirementInfo from '@/components/plugins/requirementOrderInfo.vue'
import ApplyReturnDeposit from './children/ApplyReturnDeposit.vue'
import ApplyReturnMoney from './children/ApplyReturnMoney.vue'
import ApplyExchange from './children/ApplyExchange.vue'
import EnterReturnInfo from './children/enterReturnInfo.vue'
import FileListView from '@/views/requirement/orderManagement/modules/children/fileListView.vue'

export default {
  name: 'orderDetailsForUser',
  components: {
    FileListView,
    InterestedCard,
    RequirementInfo,
    BasicInfo,
    UploadBills,
    ContractPart,
    FacilitatorBills,
    UploadProgress,
    SupplierInfo,
    SupplierUploadProgress,
    BillUploader,
    InfoDetails,
    AppraisalInfo,
    ApplyReturnDeposit,
    ApplyReturnMoney,
    ApplyExchange,
    EnterReturnInfo
  },
  data() {
    return {
      showApplyReturnInfo: false,
      showRemoveOrder: false,
      showApplyExchange: false,
      activeTab: '1',
      orderNo: '',
      orderTitle: '',
      exchangeType: '1',
      infoType: '3',
      orderStatus: null,
      purchaseId: null,
      hasEdit: false,
      purchaseNo: null,
      cancelOrder: false,
      showApplyReturnMoney: false, // 显示退款的按钮弹出
      returnType: '1', // 退款方式
      afterSaleStatus: '',
      paymentMethod: '',
      payAmount: 0,
      radioStyle: {
        display: 'block',
        height: '55px',
        lineHeight: '55px',
        color: '#000000D9',
        paddingLeft: '25px'
      },
      exchangeStatus: 10, // 换货状态
      exChangeInfo: {},
      orderItems: []
    }
  },
  created() {
    const { orderNo, orderTitle, hasEdit, purchaseId } = this.$route.query
    orderTitle && (this.orderTitle = orderTitle)
    orderNo && (this.orderNo = orderNo)
    // this.handleChangeTabs('4')
    this.purchaseId = purchaseId
    !orderTitle && this.handleLoadInfo()
    hasEdit && (this.hasEdit = hasEdit == true)
    this.handleLoadExchangeRecord()
    this.handleChangeTabs('1')

  },
  methods: {
    // 换货状态
    handleLoadExchangeRecord() {
      postAction('/refund/exchange/queryByOrderNo', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, data, message } = res
        if (success && data[0]) {
          const { exchangeStatus } = data[0]
          this.exchangeStatus = exchangeStatus
          this.exChangeInfo = data[0]
        }
      })
    },
    // 寄出旧商品
    handleSendOldGoods() {
      this.$refs.EnterReturnInfoRef.handleOpen(this.orderNo)
    },
    handleConfirmShip() {
      const that = this
      this.$confirm({
        title: '确认收到换货的新商品？',
        content: '请点击确认完成新商品收货',
        okText: '确认',
        onOk() {
          postAction('/refund/exchange/receiveGoods', {
            ...that.exChangeInfo
          }).then(res => {
            const { success, message } = res
            if (success) {
              that.handleLoadInfo()
            } else {
              that.$message.warning(message)
            }
          })
        },
        onCancel() {
          console.log('Cancel')
        },
        class: 'test'
      })
    },
    handleChat() {
      this.$refs.RequirementInfoRef.handleEmitChat()
    },
    /* 复制单号 */
    handleCopy(code) {
      const _input = document.createElement('input') // 直接构建input
      _input.value = code // 设置内容
      document.body.appendChild(_input) // 添加临时实例
      _input.select() // 选择实例内容
      document.execCommand('Copy') // 执行复制
      document.body.removeChild(_input) // 删除临时实例
      this.$message.success('复制成功')
    },

    handleLoadInfo() {
      location.reload()
    },
    handleChangeTabs(e) {
      this.$nextTick((_) => {
        if (e == '1') {
          this.$refs.SupplierInfo.handleLoadInfo()
        } else if (e == '2') {
          this.$refs.BillUploader.handleLoadTransportInfo(this.orderNo)
        } else if (e == '3') {
          this.$refs.ContractPart.handleLoadInfo()
        }
      })
    },
    handleSwitchNav(url) {
      this.$router.push(url)
    },
    /* 确认按钮 */
    handleOK() {
      // 0-待签署，1-已签署，2-已付款，3-生产中，4-已发货，5-运输中，6-已收货，7-已完结，-1已取消
      postAction('/order/transported', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, message, data } = res
        if (success) {
          // this.$message.success(message)
          this.handleLoadInfo()
        } else {
          this.$message.warning(message)
        }
      })
      this.handleChangeTabs(this.activeTab)
    },
    /* 取消订单 */
    handleRemoveOrder() {
      putAction('/order/remove', {
        orderNo: this.orderNo
      }).then((res) => {
        const { success, message } = res
        if (success) {
          this.$router.go(-1)
        } else {
          this.$message.warning(message)
        }
      })
    },
    handleEmitUpdate({ cancelOrder, paymentMethod, afterSaleStatus, payAmount, orderStatus }) {
      this.cancelOrder = cancelOrder
      this.paymentMethod = paymentMethod
      this.afterSaleStatus = afterSaleStatus
      this.payAmount = payAmount
      this.orderStatus = orderStatus
    },
    handleNextStep() {
      this.showApplyReturnInfo = false
      this.$refs.ApplyReturnDepositRef.handleEdit(this.orderNo)
    },
    /* 显示退款的弹出 */
    handleShowReturnMoney() {
      this.showApplyReturnMoney = false
      this.$refs.ApplyReturnMoneyRef.handleEdit({
        refundType: this.returnType,
        orderNo: this.orderNo
      })
    },
    /* 换货 */
    handleShowExchange() {
      this.showApplyExchange = false
      this.$refs.ApplyExchangeRef.handleEdit({
        exchangeType: this.exchangeType,
        orderNo: this.orderNo
      })
    },
    handleOrderDownload() {
      getAction('/v1/contract/queryByBizOrderNo', {
        bizOrderNo: this.orderNo
      }).then(res => {
        const { success, data, message } = res
        if (success && data && data.length) {
          const { id, contractNo, contractFileCustomer, contractFileSupplier, contractFile } = data[0]
          this.contractId = id || ''
          this.contractNo = contractNo || ''
          let fileObject = {}
          contractFile && (fileObject[contractFile] = '协议文件')
          this.$nextTick(_ => {
            this.$refs.FileListViewRef.handleGetFileList([contractFile], fileObject)
          })
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.ForUserAndPurchaseDetails {
  min-height: 580px;

  ::v-deep.ant-tabs {
    color: rgba(0, 0, 0, 0.85);
  }

  &-title {
    color: #090b12;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &-subtitle {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    grid-gap: 0 30px;

    .copy {
      font-size: 13px;
      margin-left: 8px;
      font-weight: 500;
    }
  }

  .restPart {
    text-align: right;
    flex: 1 0 0;
    min-width: 0;
  }
}

::v-deep .ant-tabs-tab-active {
  font-weight: 500;
}

.btns {
  text-align: center;
}

@import '~@/styles/detail.less';

.info-card {
  font-size: 16px;
  font-weight: 400;
  color: #262626;

  img {
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }

  .info {
    display: flex;
    align-items: center;
  }

  .memo {
    color: #5e5e66;
    font-size: 14px;
    line-height: 21px;
    margin-top: 8px;
    padding-left: 30px;

    .link {
      color: #264aff;
      cursor: pointer;
    }
  }
}

.info-area-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-part {
  .btn-inner {
    display: flex;
    align-items: center;

    .icon {
      width: 18px;
      margin-right: 8px;
    }

    span.text {
      color: #ff6026;
      font-size: 14px;
    }
  }

  ::v-deep .ant-btn {
    min-width: 103px;
    height: 38px;
    font-weight: 500;
  }
}
</style>
